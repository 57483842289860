<template>
<Header />

<div class="inner-header">
    <v-container>
        <h1>Video</h1>
        <ul class="breacrumb">
            <li class="home">
                <a href="/">Home &nbsp; <i class="fas fa-angle-right"></i></a>
            </li>
            <li>Video</li>
        </ul>
    </v-container>
</div>
<!-- Show a loading spinner while fetching data -->
<v-row justify="center" v-if="loading">
    <v-progress-circular indeterminate color="green" class="custom-loader"></v-progress-circular>
</v-row>

<!-- Show an error message if data fetching fails -->
<v-alert type="error" v-if="error">
    Failed to load data. Please try again later.
</v-alert>
<section>
    <v-container>
        <v-row>
            <!-- <v-col sm="4" cols="12" class="mb6">
                <iframe class="mfp-iframe" width="100%" height="300" src="https://greengene.citizencop.org/wp-content/uploads/2023/01/VID-20221228-WA0002-1.mp4" frameborder="0" allowfullscreen=""></iframe>
            </v-col> -->
            <v-col sm="4" cols="12" class="mb6" v-for="(video, index) in video_gallery" :key="index">
                <!-- <iframe width="100%" height="300" class="mfp-iframe" :src="video.url" frameborder="0" allowfullscreen="" autoplay="0"></iframe> -->
                <video width="100%" class="mfp-iframe" height="300" controls :src="video.url"></video>
            </v-col>
            <!-- <v-col sm="4" cols="12" class="mb6"><iframe width="100%" height="300" class="mfp-iframe" src="https://greengene.citizencop.org/wp-content/uploads/2023/01/VID-20221228-WA0006.mp4" frameborder="0" allowfullscreen="" ></iframe></v-col> -->
        </v-row>
    </v-container>
</section>
<Footer />
</template>

<script>
import axios from 'axios';
import Header from "@/components/layouts/Header.vue";
import Footer from "@/components/layouts/Footer.vue";
export default {
    components: {
        Header,
        Footer,
    },
    data() {
        return {
            loading: true,
            error: false,
            video_gallery: []
        }
    },
    created() {
        this.fetchData();
    },
    methods: {
        async fetchData() {
            this.loading = true; // Set loading to true before the request starts
            try {
                const response = await axios.post('/services/get_video');
                // Handle successful response
                const data = response.data.response;
                this.video_gallery = data.video_gallery.video_url;
                //console.log(data);
            } catch (error) {
                // Handle error
                this.error = true;
                console.error('Error fetching data:', error);
            } finally {
                this.loading = false; // Ensure loading is set to false after the request completes
            }
        }
    },
};
</script>

<style lang="scss" scoped>
.custom-loader {
    z-index: 9999;
    top: 250px;
}
</style>
