import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import './assets/scss/main.scss'
import VueLazyLoad from 'vue-lazyload'
require('vue-image-lightbox/dist/vue-image-lightbox.min.css')
loadFonts()

createApp(App)
  .use(router)
  .use(vuetify)
  .use(VueLazyLoad)
  .mount('#app')
  