<template>
<Header />
<div class="inner-header">
    <v-container>
        <h1>Who We Are</h1>
        <ul class="breacrumb">
            <li class="home"><a href="/">Home &nbsp; <i class="fas fa-angle-right"></i></a></li>
            <li>Who We Are</li>
        </ul>
    </v-container>
</div>
<div class="inner-content">
    <v-container>Who We Are Inner Content</v-container>
</div>
<Footer />
</template>

<script>
import Header from '@/components/layouts/Header.vue';
import Footer from '@/components/layouts/Footer.vue';
export default {
    components: {
        Header,
        Footer
    }
}
</script>

<style lang="scss" scoped>

</style>
