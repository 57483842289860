<template>
<v-app-bar scroll-behavior="elevate" scroll-threshold="717" class="navigation">
    <v-container>
        <v-row>
            <v-toolbar-title class="header-logo">
                <a href="/">
                    <v-img :src="require('/src/assets/images/greengene-logo.png')"></v-img>
                </a>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items class="hidden-sm-and-down">
                <template v-for="(item, index) in items" :key="item.title">
                    <v-btn :to="item.path" text v-if="index === 0">{{ item.title }}</v-btn>
                </template>

                <v-btn id="menu-activator-about">About greenGENE <v-icon icon="mdi-chevron-down" end></v-icon>
                </v-btn>
                <v-menu activator="#menu-activator-about">
                    <v-list>
                        <v-list-item v-for="(item, index) in subitems" :key="index" :value="index" :to="item.path">
                            <v-list-item-title>{{ item.title }}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
                <template v-for="(item, index) in items" :key="item.title">
                    <v-btn :to="item.path" text v-if="index >= 1">{{ item.title }}</v-btn>
                </template>
                <v-btn id="menu-activator-gallery">Captured Moments<v-icon icon="mdi-chevron-down" end></v-icon></v-btn>
                <v-menu activator="#menu-activator-gallery">
                    <v-list>
                        <v-list-item v-for="(item, index) in gallery" :key="index" :value="index" :to="item.path">
                            <v-list-item-title>{{ item.title }}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>

                <button type="button" class="btn btn-green btn-sm " @click.prevent="navigateToSection('/', 'gift-card-section')">Gift Card</button>
            </v-toolbar-items>
            <span class="hidden-sm-and-up">
                <!-- <v-toolbar @click="sidebar = !sidebar">
    </v-toolbar> -->
                <v-app-bar-nav-icon variant="text" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
            </span>
            <!-- <v-navigation-drawer v-model="sidebar" app>
  <v-list>
    <v-list-tile
      v-for="item in menuItems"
      :key="item.title"
      :to="item.path">
      <v-list-tile-action>
        <v-icon>{{ item.icon }}</v-icon>
      </v-list-tile-action>
      <v-list-tile-content>{{ item.title }}</v-list-tile-content>
    </v-list-tile>
  </v-list>
</v-navigation-drawer> -->
        </v-row>
    </v-container>
</v-app-bar>
<v-navigation-drawer v-model="drawer" location="top" temporary :elevate-on-scroll="scrollThreshold" @scroll="handleScroll">
    <!-- <v-list :items="items" link ></v-list> -->
    <!-- <v-list>
    <v-list-item v-for="(item, i) in items" :key="i" @click="menuActionClick(item.path)">
        <v-list-item-title>{{ item.title }}</v-list-item-title>
    </v-list-item>
</v-list> -->

    <!-- <v-list-item v-for="(item, i) in items" :key="i" nav link @click="$router.push({ path: item.path })">{{ item.title }}</v-list-item>
    <v-btn id="menu-activator" > About greenGENE</v-btn>
    <v-menu activator="#menu-activator">
      <v-list>
        <v-list-item v-for="(item, index) in subitems" :key="index" :value="index" :to="item.path">
          <v-list-item-title >{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu> -->

    <v-list>
        <template v-for="(item, i) in items" :key="i">
            <v-list-item nav link @click="navigateTo(item.path)" v-if="i===0"> {{ item.title }}</v-list-item>
        </template>

        <v-list-group v-model="aboutGreenGeneExpanded" append-icon="mdi-chevron-down">
            <template v-slot:activator="{ props }">
                <v-list-item v-bind="props">
                    <v-list-item-title>About greenGENE</v-list-item-title>
                </v-list-item>
            </template>

            <v-list-item v-for="(subitem, index) in subitems" :key="index" nav link @click="navigateTo(subitem.path)" :active-class="'v-list-item--active'">
                <v-list-item-title>{{ subitem.title }}</v-list-item-title>
            </v-list-item>
        </v-list-group>
        <template v-for="(item, i) in items" :key="i">
            <v-list-item nav link @click="navigateTo(item.path)" v-if="i >= 1"> {{ item.title }}</v-list-item>
        </template>
        <v-list-group v-model="mediaExpanded" append-icon="mdi-chevron-down">
            <template v-slot:activator="{ props }">
                <v-list-item v-bind="props">
                    <v-list-item-title>Captured Moments</v-list-item-title>
                </v-list-item>
            </template>

            <v-list-item v-for="(subitem, index) in gallery" :key="index" nav link @click="navigateTo(subitem.path)">
                <v-list-item-title>{{ subitem.title }}</v-list-item-title>
            </v-list-item>
        </v-list-group>

    </v-list>

</v-navigation-drawer>
</template>

<script>
export default {
    name: 'Header',
    data() {
        return {
            drawer: false,
            scrollThreshold: 717,
            aboutGreenGeneExpanded: false,
            mediaExpanded: false,
            //group: null,
            // links: [
            //     'Home',
            //     'About Us',
            //     'Team',
            //     'Services',
            //     'Blog',
            //     'Contact Us',
            // ],
            items: [{
                    title: 'Home',
                    path: '/',
                    icon: 'home'
                },
                {
                    title: 'Tree Tagging',
                    path: '/treeTagging',
                    icon: 'face'
                },
                {
                    title: 'Carbon Credits',
                    path: '/carbonCredits',
                    icon: 'face'
                },
                //{ title: 'Media/Gallery', path: '/mediaGallery', icon: 'face' },
                // { title: 'Sign In', path: '/signin', icon: 'lock_open' }
            ],
            subitems: [{
                    title: 'How We Work',
                    path: '/howwework',
                    icon: 'face'
                },
                {
                    title: 'Concept',
                    path: '/concept',
                    icon: 'face'
                },
                {
                    title: 'Initiatives',
                    path: '/initiatives',
                    icon: 'face'
                },
                {
                    title: 'Become a Volunteer',
                    path: '/becomeavolunteer',
                    icon: 'face'
                },
            ],
            gallery: [{
                    title: 'Media',
                    path: '/media',
                    icon: 'face'
                },
                {
                    title: 'Gallery',
                    path: '/mediaGallery',
                    icon: 'face'
                },
                {
                    title: 'Video',
                    path: '/video',
                    icon: 'face'
                },
            ],
        }
    },
    // watch: {
    //     group() {
    //         this.drawer = false
    //     },
    // },
    methods: {
        navigateTo(path) {
            this.$router.push({
                path
            });
            this.drawer = false; // Close the drawer after navigation
        },
        handleScroll(event) {
            const threshold = this.scrollThreshold;
            const scrolled = window.scrollY > threshold;
            this.$emit('update:elevateOnScroll', scrolled);
        },
        navigateToSection(path, sectionId) {
            this.$router.push({
                path,
                hash: `#${sectionId}`
            });
        }
    },
}
</script>

<style lang="scss" scoped>

</style>
