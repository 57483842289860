<template>
<Header />
<div class="inner-header">
    <v-container>
        <h1>Privacy Policy</h1>
        <ul class="breacrumb">
            <li class="home"><a href="/">Home &nbsp; <i class="fas fa-angle-right"></i></a></li>
            <li>Privacy Policy</li>
        </ul>
    </v-container>
</div>
<!-- Show a loading spinner while fetching data -->
<v-row justify="center" v-if="loading">
    <v-progress-circular indeterminate color="green" class="custom-loader"></v-progress-circular>
</v-row>

<!-- Show an error message if data fetching fails -->
<v-alert type="error" v-if="error">
    Failed to load data. Please try again later.
</v-alert>

<div class="inner-content extrapage">

    <v-container v-if="!loading && !error">
        <div v-html="detail.content"></div>
   <!-- <p>     greenGENE is a green initiative by Citizen COP Foundation, Indore. It is a registered non-profit organization based in Indore, Madhya Pradesh. The Foundation works towards a variety of social causes and leverages technology to address these issues. Our work area is spread across social issues like safety, women empowerment, juvenile justice, education, environment conservation and more.
</p><p>
Our Privacy Policy describes how do we obtain and make use of your personal data. This clearly describes about your rights and access to data. Kindly, review it carefully. We take your privacy for granted and use your personal data as further explained in this Privacy Policy. However, we acknowledge that the privacy of our customers’ personal information is very important, and we are committed to keep it confidential.
</p><p>
We do not collect any personal information about you on our website unless you choose to provide it to us voluntarily. If you decide to place an order with us, there is certain personal information that we will require from you in order to process your order.
</p>
<h4>Automatically Collected Information</h4>
<p>The Application may collect certain information automatically, including, but not limited to, the type of mobile device you use, your mobile devices unique device ID, the IP address of your mobile device, your mobile operating system, the type of mobile Internet browsers you use, and information about the way you use the Application.
</p><p>
For certain features, the App may collect real-time location information of the device. When you visit the mobile application, we may use GPS technology (or other similar technology) to determine your current location in order to determine the city you are located within and display a location map. We will not share your current location with other users or partners without your consent.
</p>
<h4>Details We Need</h4>
<p>To contact you in response to an enquiry you make via our Site or in relation to the products and services, we need some of your details that include:
</p>
<ul class="custom-list">
<li>Your name</li>
<li>Email address</li>
<li>Telephone number</li>
</ul>



<h4>How We Use Your Data?</h4>
<ul class="custom-list">
<li>To offer you with the requested QR codes for trees and services including but not limited to processing and fulfilling your order.
</li><li>To process your payment for requested QR codes for trees and services
</li><li>For the purposes of administering promotions such as competitions and prize draws for QR codes.
</li><li>To monitor and improve our services as well as our website.
</li><li>To handle your marketing preferences, including opting out entirely.
</li><li>To contact you if there is a query regarding your order.
</li><li>To verify your identity.</li>
</ul>
<h4>Information to Third Party</h4>
Only aggregated, anonymized data is periodically transmitted to external services to help us improve the Application and our service. We will share your information with third parties only in the ways that are described in this privacy statement.
<ul class="custom-list">
    <li>We may disclose User Provided and Automatically Collected Information:
    </li><li>As required by law, such as to comply with a subpoena, or similar legal process;
    </li><li>When we believe in good faith that disclosure is necessary to protect our rights, protect your safety or the safety of others, investigate fraud, or respond to a government request;
    </li><li>With our trusted services providers who work on our behalf, do not have an independent use of the information we disclose to them and have agreed to adhere to the rules set forth in this privacy statement.
    </li></ul>
<h4>Payment Processing</h4>
greenGENE is integrated with Citizen COP foundation for contributing to social cause, your details may be disclosed to our advisers for great quality of work. We are not-for-profit foundation. Any charges that user pays is for the technology platform, tree tag generation and printing costs (if any).

<h4>Updates to Policy:</h4>
<p>If we change our Policy, we will post the changes on this page, so that you can check our current policy at any time. Use of the website or mobile application is considered as the acceptance of this privacy policy.
</p><p>
This policy was last updated in July 2019.
</p><p>
The Terms, Policy and any other agreements between the Parties are governed by the laws, rules and regulations of India and it is agreed by the Parties. The Courts at Indore (Madhya Pradesh) shall have exclusive jurisdiction over any disputes arising between the Parties.
</p>
<h5>Reach Us</h5>
<p>E-mail: <a href="mailto:info@citizencop.org" class="text-success">info@citizencop.org</a> <br/> Telephone: <a class="text-success" href="tel:+917771911911">+91-777-191-1911</a>  (WhatsApp only)
  </p>  -->
    </v-container>
</div>
<Footer />
</template>

<script>
import axios from 'axios';
import Header from "@/components/layouts/Header.vue";
import Footer from "@/components/layouts/Footer.vue";
export default {
    components: {
        Header,
        Footer,
    },
    data() {
        return {
            loading: true,
            error: false,
            detail: [],
        }
    },
    created() {
        this.fetchData();
    },
    methods: {
        async fetchData() {
            this.loading = true; // Set loading to true before the request starts
            try {
                let axiosConfig = {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                    }
                };

                let postdata = {
                    page_type: 'privacy-policy'
                };
                const response = await axios.post('services/get_disclaimer', postdata, axiosConfig);
                // Handle successful response
                const data = response.data;
                this.detail = data.detail;

                //console.log(data);
            } catch (error) {
                // Handle error
                this.error = true;
                console.error('Error fetching data:', error);
            } finally {
                this.loading = false; // Ensure loading is set to false after the request completes
            }
        },
    }
};
</script>

<style scoped>
.custom-loader {
    z-index: 9999;
    top: 250px;
}
</style>

