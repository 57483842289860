import { createRouter, createWebHistory } from 'vue-router'
import Layout from '@/components/layouts/Layout.vue';
import DisclaimerView from '@/views/DisclaimerView.vue';
import PrivacyPolicyView from '@/views/PrivacyPolicyView.vue';
import ReturnPolicyView from '@/views/ReturnPolicyView.vue';
import TermsAndConditionsView from '@/views/TermsAndConditionsView.vue';
import PricingPackagesView from '@/views/PricingPackagesView.vue';
import WhoWeAreView from '@/views/WhoWeAreView.vue';
import MediaView from '@/views/MediaView.vue';
import VideoView from '@/views/VideoView.vue';

const routes = [
  {
    path: '/',
    component: Layout,
    children: [
      {
        path: '',
        component: () => import('@/views/HomeView.vue'),
        name: 'home',
      }
    ],
  },
  {
    path: '/aboutGreengene',
    component: Layout,
    children: [
      {
        path: '',
        component: () => import('@/views/AboutGreengeneView.vue'),
        name: 'aboutGreengene',
      },
    ],
  },
  {
    path: '/howwework',
    component: Layout,
    children: [
      {
        path: '',
        component: () => import('@/views/HowweworkView.vue'),
        name: 'howwework',
      },
    ],
  },
  {
    path: '/concept',
    component: Layout,
    children: [
      {
        path: '',
        component: () => import('@/views/ConceptView.vue'),
        name: 'concept'
      },
    ],
  },
  {
    path: '/initiatives',
    component: Layout,
    children: [
      {
        path: '',
        component: () => import('@/views/InitiativesView.vue'),
        name: 'initiatives',
      },
    ],
  },
  {
    path: '/becomeavolunteer',
    component: Layout,
    children: [
      {
        path: '',
        component: () => import('@/views/BecomeavolunteerView.vue'),
        name: 'becomeavolunteer',
      },
    ],
  },
  {
    path: '/treeTagging',
    component: Layout,
    children: [
      {
        path: '',
        component: () => import('@/views/TreeTaggingView.vue'),
        name: 'treeTagging',
      },
    ],
  },
  {
    path: '/carbonCredits',
    component: Layout,
    children: [
      {
        path: '',
        component: () => import('@/views/CarbonCreditsView.vue'),
        name: 'carbonCredits',
      },
    ],
  },
  {
    path: '/mediaGallery',
    component: Layout,
    children: [
      {
        path: '',
        component: () => import('@/views/MediaGalleryView.vue'),
        name: 'mediaGallery',
      },
    ],
  },
  {
    path: '/ourTeam',
    component: Layout,
    children: [
      {
        path: '',
        component: () => import('@/views/OurTeam.vue'),
        name: 'ourTeam',
      },
    ],
  },
  { path: '/disclaimer', name: 'disclaimer', component: DisclaimerView },
  { path: '/privacy-policy', name: 'privacy-policy', component: PrivacyPolicyView },
  { path: '/return-policy', name: 'return-policy', component: ReturnPolicyView },
  { path: '/terms-and-conditions', name: 'terms-and-conditions', component: TermsAndConditionsView },
  { path: '/who-we-are', name: 'who-we-are', component: WhoWeAreView },
  { path: '/pricing-packages', name: 'pricing-packages', component: PricingPackagesView },
  { path: '/media', name: 'media', component: MediaView },
  { path: '/video', name: 'video', component: VideoView },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
      };
    }
    return savedPosition || { top: 0 };
  },
})

export default router
