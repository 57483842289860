<template>
<Header />
<div class="inner-header">
    <v-container>
        <h1>Disclaimer</h1>
        <ul class="breacrumb">
            <li class="home">
                <a href="/">Home &nbsp; <i class="fas fa-angle-right"></i></a>
            </li>
            <li>Disclaimer</li>
        </ul>
    </v-container>
</div>
<!-- Show a loading spinner while fetching data -->
<v-row justify="center" v-if="loading">
    <v-progress-circular indeterminate color="green" class="custom-loader"></v-progress-circular>
</v-row>

<!-- Show an error message if data fetching fails -->
<v-alert type="error" v-if="error">
    Failed to load data. Please try again later.
</v-alert>

<div class="inner-content extrapage" >
    <v-container v-if="!loading && !error">
        <div v-html="detail.content"></div>
        <!-- <p>
            The motive of greenGENE is to promote awareness of organized plantation.
            The statistics and other data might be taken from third party sources.
            Credit for the statistics shall be given if asked. Team GreenGENE is not
            responsible for any discrepancy in data.
        </p>
        <p>
            We have made the best efforts to ensure the accuracy of data but we are
            not responsible for any incorrect information. We do not hold any
            obligation to update or enhance the app and its features. The Terms,
            Policy and any other agreements between the Parties are governed by the
            laws, rules and regulations of India and it is agreed by the Parties.
            The Courts at Indore (Madhya Pradesh) shall have exclusive jurisdiction
            over any disputes arising between the Parties. For any query, please
            feel free to contact us via email at </p>
        <p>E-mail: <a href="mailto:info@citizencop.org" class="text-success">info@citizencop.org</a> <br /> Telephone: <a href="tel:+917771911911" class="text-success">+91-777-191-1911</a> (WhatsApp only)
        </p> -->

    </v-container>
</div>
<Footer />
</template>

<script>
import axios from 'axios';
import Header from "@/components/layouts/Header.vue";
import Footer from "@/components/layouts/Footer.vue";
export default {
    components: {
        Header,
        Footer,
    },
    data() {
        return {
            loading: true,
            error: false,
            detail: [],
        }
    },
    created() {
        this.fetchData();
    },
    methods: {
        async fetchData() {
            this.loading = true; // Set loading to true before the request starts
            try {
                let axiosConfig = {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                    }
                };

                let postdata = {
                    page_type: 'disclaimer'
                };
                const response = await axios.post('services/get_disclaimer', postdata, axiosConfig);
                // Handle successful response
                const data = response.data;
                this.detail = data.detail;

                //console.log(data);
            } catch (error) {
                // Handle error
                this.error = true;
                console.error('Error fetching data:', error);
            } finally {
                this.loading = false; // Ensure loading is set to false after the request completes
            }
        },
    }
};
</script>

<style scoped>
.custom-loader {
    z-index: 9999;
    top: 250px;
}
</style>

