<template>

<Header />

<router-view />

<Footer />
</template>

    
<script>
import Header from '@/components/layouts/Header.vue'
import Footer from '@/components/layouts/Footer.vue'
import Top from '@/components/layouts/Top.vue'

export default {
    name: 'Layout',
    components: {
        Header,
        Footer,
        Top,
    }
}
</script>

    
<style lang="scss" scoped>

    </style>
