<template>
<Header />
<div class="inner-header">
    <v-container>
        <h1>Terms And Conditions</h1>
        <ul class="breacrumb">
            <li class="home"><a href="/">Home &nbsp; <i class="fas fa-angle-right"></i></a></li>
            <li>Terms And Conditions</li>
        </ul>
    </v-container>
</div>
<div class="inner-content extrapage">
        <v-container v-if="!loading && !error">
        <div v-html="detail.content"></div>

      <!-- <h4> Who We Are?</h4> 
greenGENE is a social initiative that uses technology for executing conservation in the best way possible with the help of geo tagging and geo-mapping etc. ultimately leading to the maintenance of the eco-balance. The initiative is under Citizen COP Foundation, a registered NGO in Indore. Our registered office in Indore, India is at: 21/1, Race Course Road Indore (M.P.) 452003.

<h4>Introduction</h4>
Any user who uses the greenGENE mobile application, website or associates with us for tree tags is liable to the terms and conditions set in this document. We are a technology enabler for protecting green environment against a nominal donation.

<h4>QR Tagging With greenGENE</h4>
<ul class="custom-list">
<li>To achieve our objective of increasing attention towards plants and post plantation needs, we enable technology with QR tagging of a tree against a very nominal donation.
</li><li>The nominal donation generating unique QR code and tag to store health record of planted trees allows user to use Application for 1 year and after a year it needs to be renewed again with some additional donation.
</li><li>Visit greengene.citizencop.org to get more details about QR tagging with greenGENE for tree tagging and using technology as enabler to develop better binding of persons with tress and with an objective to increase overall survival rate of plants.
</li><li>We wish to promote the cause supported by greenGENE as far as we can. We are not responsible for any delay due to problems in printing, logistics or delivery of QR tags.
</li></ul>
<h4>Acceptance of Donation</h4>
<ul class="custom-list">
<li>We accept donation through net banking and debit cards.
</li><li>This donation is done to Citizen COP Foundation, the NGO behind greenGENE. It is not for profit, transaction. The donation is for greenGENE, technology platform, tree health record storage and generation of unique QR code to store trees details like its size, location, photographs, description, type etc. and accordingly, retrieval of such information by scanning the same QR code using greenGENE mobile App. Another way of donation is for one or multiple number of trees to be planted by our NGO and its maintenance for 1 year along with digital record uploading. Using this donation, one can assign our NGO a task to plant and maintain a tree for at least one year and Gift that tree in form of Digital QR code to anyone with personalized message and a tree memento.
</li><li>Since there is not a sale or any commercial service or commodity involved, rather the whole idea is to make people know that how technology can play an IMP role in plantation, freezing green memories and post planation care, Once payment of donation is done, the technology automatically generates desired number of QR tags and make it usable to the user. There is no provision of any refund for these donations. Though in case of any technical assistance, user or donor can definitely ask for any clarification of assistance over email or WhatsApp messages.
</li><li>All donations by net banking, credit card or debit card need to be authorized by the relevant bank or card issuer.However, in the absence of negligence on our part we will not be legally responsible to you for any loss that you may suffer if a third party gains unauthorized access to any information that you give us.
</li></ul>
<h4>Contact Us</h4>
<p>IF YOU DON'T UNDERSTAND ANY OF THIS DETAILS AND WANT TO TALK TO US ABOUT IT, PLEASE CONTACT US!
</p>
<p>E-mail: <a href="mailto:info@citizencop.org" class="text-success">info@citizencop.org</a> <br/> Telephone: <a href="tel:+917771911911" class="text-success">+91-777-191-1911</a>  (WhatsApp only)
  </p>  -->
   </v-container>
</div>
<Footer />
</template>

<script>
import axios from 'axios';
import Header from "@/components/layouts/Header.vue";
import Footer from "@/components/layouts/Footer.vue";
export default {
    components: {
        Header,
        Footer,
    },
    data() {
        return {
            loading: true,
            error: false,
            detail: [],
        }
    },
    created() {
        this.fetchData();
    },
    methods: {
        async fetchData() {
            this.loading = true; // Set loading to true before the request starts
            try {
                let axiosConfig = {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                    }
                };

                let postdata = {
                    page_type: 'terms-conditions'
                };
                const response = await axios.post('services/get_termsconditions', postdata, axiosConfig);
                // Handle successful response
                const data = response.data;
                this.detail = data.detail;

                //console.log(data);
            } catch (error) {
                // Handle error
                this.error = true;
                console.error('Error fetching data:', error);
            } finally {
                this.loading = false; // Ensure loading is set to false after the request completes
            }
        },
    }
};
</script>

<style scoped>
.custom-loader {
    z-index: 9999;
    top: 250px;
}
</style>

