<template>
<div class="contact">
    <v-container>
        <v-col sm="5" class="offset-sm-7 contact-form">
            <h2 class="text-white">Get In Touch</h2>

            <v-form ref="form" v-model="valid">
                <v-container>
                    <v-row>
                        <v-col cols="12" class="inputheight pb1">
                            <v-text-field v-model="name" label="Name" :rules="[rules.required]" :error-messages="nameErrors" hide-details="auto" required ref="nameField"></v-text-field>
                        </v-col>
                        <v-col cols="12" class="inputheight pb1">
                            <v-text-field v-model="email" label="Email" :rules="[rules.required, rules.email]" :error-messages="emailErrors" hide-details="auto" required ref="emailField"></v-text-field>
                        </v-col>
                        <v-col cols="12" class="inputheight pb1">
                            <v-text-field v-model="phone" label="Contact No" :rules="[rules.required,rules.phone]" :error-messages="phoneErrors" hide-details="auto" required ref="phoneField"></v-text-field>
                        </v-col>
                        <v-col cols="12" class="textarea">
                            <v-textarea v-model="message" label="Message" :rules="[rules.required]" :error-messages="messageErrors" hide-details="auto" required ref="messageField"></v-textarea>
                        </v-col>
                        <v-col cols="12" class="p-0">
                            <v-btn class="mt-2 btn btn-yellow" :loading="loading" @click="submitForm">Submit</v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </v-form>
        </v-col>
    </v-container>

    <div class="clearfix"></div>
    <v-footer class="footer">
        <v-container>
            <v-row>
                <v-col sm="3" cols="12">
                    <div>
                    <a href="/">
                    <v-img :src="require('/src/assets/images/footer-logo.png')" class="logo"></v-img>
                    </a>
                    <p>greenGENE uses QR tagging to track trees, promoting accountability and aiding tree census efforts to boost survival rates. This initiative raises awareness for tree conservation and encourages organized plantation.</p>
                    </div>
                    <div class="mt8 w-100" >
                        <ul class="social-link">
                            <li><a href="https://www.facebook.com/greenGENEIndore/" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                            <li><a href="https://twitter.com/greenGENEIndore/" target="_blank"><i class="fab fa-twitter"></i></a></li>
                            <li><a href="https://www.linkedin.com/showcase/greengeneindore/" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                            <li><a href="https://www.instagram.com/greengeneindore/" target="_blank"><i class="fab fa-instagram"></i></a></li>
                            <li><a href="https://in.pinterest.com/greengeneindore/_saved/" target="_blank"><i class="fab fa-pinterest-p"></i></a></li>
                        </ul>
                    </div>
                    <div class="clearfix"></div>
                  
                </v-col>
                <v-col sm="2" cols="12" class="offset-sm-1">
                    <h5 class="mb4">greenGENE </h5>
                    <ul class="footer-link">
                        <li><a href="/">Home</a></li>
                        <li><a href="/howwework">How We Work</a></li>
                        <li><a href="/#"  @click.prevent="navigateToSection('/', 'gift-card-section')">Gift Cards</a></li>
                        <li><a href="/treeTagging">Tree Tagging</a></li>
                        <li><a href="/carbonCredits">Carbon Credits</a></li>
                        <li><a href="/ourTeam">Our Team</a></li>
                        <li><a href="/mediaGallery">Gallery</a></li>
                    </ul>
                </v-col>
                <v-col sm="2" class="offset-sm-1" cols="12">
                    <h5 class="mb4">Policies</h5>
                    <!-- <ul class="footer-link">
<li><a href="#">Disclaimer   </a></li>
  <li><a href="#">Privacy Policy </a></li>
    <li><a href="#">Return Policy   </a></li>
      <li><a href="#">Terms & Condition </a></li>
        <li><a href="#">Who we are</a></li>
          <li><a href="#">Pricing Packages</a></li>
               </ul> -->
                    <ul class="footer-link">
                        <li>
                            <router-link to="/disclaimer">Disclaimer</router-link>
                        </li>
                        <li>
                            <router-link to="/privacy-policy">Privacy Policy</router-link>
                        </li>
                        <li>
                            <router-link to="/return-policy">Return Policy</router-link>
                        </li>
                        <li>
                            <router-link to="/terms-and-conditions">Terms & Condition</router-link>
                        </li>
                        <!-- <li>
                            <router-link to="/who-we-are">Who we are</router-link>
                        </li> -->
                        <li>
                            <router-link to="/pricing-packages">Pricing Packages</router-link>
                        </li>
                    </ul>
                    <div class="">
            <v-row >
                <v-col sm="6" cols="6"> <a href="https://apps.apple.com/in/app/greengene/id1429856765" target="_blank" @click="handleClick">
                        <v-img :src="require('/src/assets/images/appstore.png')" width="110"></v-img>
                    </a></v-col>
                <v-col sm="6" cols="6"> <a href="https://play.google.com/store/apps/details?id=com.greenGENE" target="_blank" @click="handleClick">
                        <v-img :src="require('/src/assets/images/playstore.png')" width="110"></v-img>
                    </a></v-col>
            </v-row>
        </div>
                </v-col>
                <v-col sm="2" class="offset-sm-1" cols="12">
                    <h5 class="mb4">Contact Us</h5>
                    <table class="w-100">
                        <tr>
                            <td width="40" valign="top" class="pt2">
                                <v-img :src="require('/src/assets/images/map.png')" width="20"></v-img>
                            </td>
                            <td>21/1 Race Course Road
                                Indore (M.P.) - 452 003</td>
                        </tr>
                        <tr>
                            <td class="pt2">
                                <v-img :src="require('/src/assets/images/call.png')" width="20"></v-img>
                            </td>
                            <td class="pt2"><a href="tel:+917771911911">+91-777-191-1911</a> (WhatsApp only)</td>
                        </tr>
                        <tr>
                            <td class="pt2">
                                <v-img :src="require('/src/assets/images/mail.png')" width="20"></v-img>
                            </td>
                            <td class="pt2"><a href="mailto:info@citizencop.org">info@citizencop.org</a></td>
                        </tr>

                    </table>
                </v-col>
            </v-row>
        </v-container>
    </v-footer>
</div>
<div class="copyright">All Right Reserved © 2024 CitizenCOP</div>
</template>

<script>
import axios from 'axios';

export default {
    name: 'Footer',
    data() {
        return {
            loading: false,
            valid: false, // Initialize the valid property
            name: '',
            // nameRules: [
            //     v => !!v || 'Name is required',
            //     v => (v && v.length >= 3) || 'Name must be at least 3 characters',
            // ],
            phone: '',
            email: '',
            message: '',
            nameErrors: [],
            phoneErrors: [],
            emailErrors: [],
            messageErrors: [],
            rules: {
                required: value => !!value || 'Required.',
                email: value => {
                    const pattern = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
                    return pattern.test(value) || 'Invalid email.';
                },
                phone: value => {
                    const pattern = /^\d+$/;
                    return pattern.test(value) || 'Contact No must be numeric.';
                },
            },
        };
    },
    methods: {
        async submitForm() {
            // Reset error messages
            this.nameErrors = [];
            this.phoneErrors = [];
            this.emailErrors = [];
            this.messageErrors = [];

            // Validate the form
            const formValid = await this.$refs.form.validate();

            if (formValid.valid) {
                this.loading = true
                try {

                    let axiosConfig = {
                        headers: {
                            "Access-Control-Allow-Origin": "*",
                            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                        }
                    };

                    let postdata = {
                        full_name: this.name,
                        email: this.email,
                        message: this.message,
                        phone: this.phone,
                    };
                    const response = await axios.post('services/add_contactus', postdata, axiosConfig);

                    if (response.data.error == '0') {
                        console.log(response.data.status);
                        alert("Thankyou for contacting Us!");
                        this.resetForm();
                        this.loading = false;
                    } else {
                        this.loading = false;
                        alert(JSON.stringify(response.data));
                        alert(response.data.status);
                    }
                    // console.log('Form submitted successfully', response);
                } catch (error) {
                    console.error('Form submission error', error);
                    alert('Somthing Went Wrong!');
                    this.loading = false;
                }
            } else {
                // Collect error messages from each field
                this.nameErrors = this.$refs.nameField.errors;
                this.phoneErrors = this.$refs.phoneField.errors;
                this.emailErrors = this.$refs.emailField.errors;
                this.messageErrors = this.$refs.messageField.errors;
                this.loading = false;
                console.log('Form validation failed');
            }
        },
        resetForm() {
            this.$refs.form.reset();
            this.$refs.form.resetValidation();
        },
        navigateToSection(path, sectionId) {
            this.$router.push({ path, hash: `#${sectionId}` });
        }
        
    },
};
</script>

<style lang="scss" scoped>

</style>
