<template>
<Header />
<div class="inner-header">
    <v-container>
        <h1>Media</h1>
        <ul class="breacrumb">
            <li class="home">
                <a href="/">Home &nbsp; <i class="fas fa-angle-right"></i></a>
            </li>
            <li>Media</li>
        </ul>
    </v-container>
</div>
<!-- Show a loading spinner while fetching data -->
<v-row justify="center" v-if="loading">
    <v-progress-circular indeterminate color="green" class="custom-loader"></v-progress-circular>
</v-row>

<!-- Show an error message if data fetching fails -->
<v-alert type="error" v-if="error">
    Failed to load data. Please try again later.
</v-alert>

<section>
    <!-- <v-container>
        <div class="gallery">
            <div v-for="(image, index) in images" :key="index" class="gallery-item ">
                <img :src="image" @click="showLightbox(index)" alt="Gallery Image" />
            </div>
            <vue-easy-lightbox :visible="visible" :imgs="images" :index="index" @hide="handleHide" />
        </div>
    </v-container> -->

    <v-container>
        <div class="gallery" v-if="!loading && !error">
            <div v-for="(image, index) in media_gallery" :key="index" class="gallery-item ">
                <img :src="image.url" @click="showLightbox(index)" :alt="image.alt" :caption="image.caption" :title="image.title" :description="image.description" />
            </div>
            <vue-easy-lightbox :visible="visible" :imgs="mediaGalleryUrls" :index="index" @hide="handleHide" />
        </div>
    </v-container>
</section>
<Footer />
</template>

<script>
import axios from 'axios';
import Header from "@/components/layouts/Header.vue";
import Footer from "@/components/layouts/Footer.vue";
import VueEasyLightbox from 'vue-easy-lightbox'

export default {
    data() {
        return {
            images: [
                require('@/assets/images/media/1.jpg'),
                require('@/assets/images/media/2.jpg'),
                require('@/assets/images/media/3.jpg'),
                require('@/assets/images/media/4.jpg'),
                require('@/assets/images/media/5.jpg'),
                require('@/assets/images/media/6.jpg'),
                require('@/assets/images/media/7.jpg'),
                require('@/assets/images/media/8.jpg'),
                require('@/assets/images/media/9.jpg'),
                require('@/assets/images/media/10.jpg'),
                require('@/assets/images/media/11.jpg'),
                require('@/assets/images/media/12.jpg'),

                // Add more images as needed
            ],
            visible: false,
            index: 0,
            loading: true,
            error: false,
            media_gallery: []
        }
    },
    created() {
        this.fetchData();
    },
    computed: {
        mediaGalleryUrls() {
            return this.media_gallery.map(image => image.url);
        }
    },
    methods: {
        async fetchData() {
            this.loading = true; // Set loading to true before the request starts
            try {
                const response = await axios.post('/services/get_media');
                // Handle successful response
                const data = response.data.response;
                this.media_gallery = data.media_gallery;
                //console.log(data);
            } catch (error) {
                // Handle error
                this.error = true;
                console.error('Error fetching data:', error);
            } finally {
                this.loading = false; // Ensure loading is set to false after the request completes
            }
        },
        showLightbox(index) {
            this.index = index
            this.visible = true
        },
        handleHide() {
            this.visible = false
        },
    },
    components: {
        Header,
        Footer,
        VueEasyLightbox,
    },
};
</script>

<style lang="scss" scoped>
.custom-loader {
    z-index: 9999;
    top: 250px;
}
</style>
